import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const Header = () => {
  let navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState<string>(i18n.language);
  const [dropdown, setDropdown] = useState<boolean>(false);

  function changeLang(language: string) {
    setLang(
      language === "es-ES"
        ? "ES"
        : language === "it-IT"
        ? "IT"
        : language === "en-US"
        ? "EN"
        : language === "pt-BR"
        ? "PT"
        : language === "sk-SK"
        ? "SK"
        : "ES"
    );
    i18n.changeLanguage(language);
    setDropdown(false);
  }

  useEffect(() => {
    changeLang(i18n.language);
  }, []);

  return (
    <div className="w-full absolute top-0 flex justify-between">
      <div className="mt-8 ml-8 relative">
        <button
          id="dropdownDefaultButton"
          className="text-blueVirtual bg-white flex items-center p-3 font-medium text-lg relative w-20"
          type="button"
          onClick={() => setDropdown(!dropdown)}
        >
          {lang}{" "}
          <svg
            className="w-4 h-4 ml-2"
            width="12"
            height="9"
            viewBox="0 0 12 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.99809 0.462241C3.88331 0.465005 3.75174 0.462241 3.61176 0.462241C2.65153 0.456715 1.33855 0.451188 0.915824 1.15586C0.697462 1.51787 0.756252 2.00147 1.08939 2.59837C1.86486 3.99113 2.87269 5.19599 3.75734 6.19358C3.92251 6.37873 4.09328 6.59428 4.27245 6.82364C4.91074 7.63332 5.63302 8.55078 6.49247 8.55078C6.94599 8.55078 7.38551 8.29102 7.79424 7.77702C7.85303 7.70241 7.93702 7.60016 8.0406 7.47305C8.71528 6.64402 10.2942 4.7041 11.1341 3.12618C11.5176 2.40769 11.708 1.83566 11.708 1.40733C11.708 1.08677 11.6016 0.843594 11.3888 0.675025C10.9577 0.335124 10.269 0.351704 9.7623 0.365521L3.99809 0.462241Z"
              fill="#315DDB"
            />
          </svg>
          <div className="absolute inset-x-0 top-0 w-[103%]">
            <img className="w-full" src="./images/hline.png" alt="Test" />
          </div>
          <div className="absolute inset-x-0 bottom-0 w-[105%]">
            <img className="w-full" src="./images/hline.png" alt="Test" />
          </div>
          <div className="absolute inset-y-0 left-0 -mt-1">
            <img className="h-[106%]" src="./images/vline.png" alt="Test" />
          </div>
          <div className="absolute inset-y-0 right-0 -mt-1">
            <img className="h-[102%]" src="./images/vline.png" alt="Test" />
          </div>
        </button>

        <div
          className={`z-10 absolute w-full bg-white  rounded shadow ${
            dropdown ? "" : "hidden"
          }`}
        >
          <ul className="py-1 text-sm text-gray-700 divide-y divide-gray-100">
            <li
              className="flex justify-center items-center py-2 cursor-pointer"
              onClick={() => changeLang("es-ES")}
            >
              ES
            </li>
            <li
              className="flex justify-center items-center py-2 cursor-pointer"
              onClick={() => changeLang("it-IT")}
            >
              IT
            </li>
            <li
              className="flex justify-center items-center py-2 cursor-pointer"
              onClick={() => changeLang("en-US")}
            >
              EN
            </li>
            <li
              className="flex justify-center items-center py-2 cursor-pointer"
              onClick={() => changeLang("pt-BR")}
            >
              PT
            </li>
            <li
              className="flex justify-center items-center py-2 cursor-pointer"
              onClick={() => changeLang("sk-SK")}
            >
              SK
            </li>
          </ul>
        </div>
      </div>
      <div
        onClick={() => {
          navigate("/login");
        }}
        className="flex justify-center items-center gap-3 mt-8 mr-8 cursor-pointer hover:scale-105"
      >
        <svg
          width="21"
          height="27"
          viewBox="0 0 21 27"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.4511 4.82525C15.4276 5.68993 15.3049 6.54734 15.086 7.37546C14.8175 8.44662 14.418 9.46907 13.8993 10.4125C13.3078 11.4913 12.5798 12.464 11.7389 13.2989C10.7892 14.235 9.68085 14.9374 8.48308 15.3623C7.71318 15.6894 6.9005 15.8658 6.07926 15.8839C5.351 15.9103 4.62582 15.7643 3.9493 15.455C3.3366 15.1614 2.80166 14.6898 2.39747 14.0872C1.9471 13.3824 1.67991 12.5467 1.62662 11.6761C1.52494 10.4854 1.63183 9.28348 1.94105 8.14053C2.25842 6.93877 2.75605 5.80915 3.41174 4.80206C4.08791 3.75085 4.89424 2.8176 5.80541 2.0316C6.7669 1.21082 7.83003 0.558375 8.95979 0.0957487C9.0772 0.0326926 9.20534 0 9.33507 0C9.4648 0 9.59293 0.0326926 9.71035 0.0957487C9.84064 0.181203 9.95047 0.302086 10.0301 0.447677C10.1097 0.593268 10.1567 0.759067 10.1668 0.930365C11.5429 0.980963 12.8886 1.40619 14.092 2.1707C14.5048 2.43485 14.8484 2.82015 15.0873 3.28681C15.3262 3.75347 15.4518 4.28457 15.4511 4.82525ZM3.81745 12.5223C3.86138 12.9625 4.03693 13.372 4.31444 13.6815C4.56846 13.9137 4.86763 14.0727 5.18671 14.1451C5.56166 14.178 5.93878 14.1388 6.3024 14.0292C6.71572 13.9161 7.12226 13.7729 7.51953 13.6003C7.89217 13.435 8.25474 13.2413 8.6048 13.0207C8.89105 12.8446 9.16265 12.6389 9.41621 12.4064C10.5636 11.423 11.5174 10.175 12.2156 8.74331C12.9379 7.27437 13.3932 5.65392 13.5544 3.97904C13.5544 3.7472 13.3921 3.51536 13.0574 3.28352C12.6629 3.02335 12.2351 2.8356 11.7896 2.72711C11.2723 2.5996 10.6942 2.49527 10.0755 2.41413C9.47615 2.33741 8.87225 2.31802 8.27009 2.35617C7.63182 3.02934 7.02576 3.74135 6.45455 4.48908C5.88078 5.24379 5.38083 6.06783 4.96357 6.94656C4.54942 7.80394 4.23569 8.71982 4.03044 9.67066C3.81217 10.5993 3.74005 11.5648 3.81745 12.5223Z"
            fill="#315DDB"
          />
          <path
            d="M19.9066 22.3294C20.2109 24.2536 20.1602 24.7405 20.0385 24.7753C19.9168 24.81 19.3285 22.6539 17.5434 21.1934C17.0563 20.8146 16.5277 20.5106 15.9713 20.2892C14.3978 19.6346 12.7367 19.2973 11.0622 19.2923C7.6137 19.2227 4.52018 19.1648 3.4552 21.147C3.33349 21.3904 3.37406 21.402 2.20765 24.2536C1.97136 24.8434 1.66036 25.3903 1.28467 25.8765C0.828245 26.4445 0.452965 26.9314 0.270397 26.827C-0.307736 26.5604 -0.115025 22.0396 2.4105 19.5937C2.58886 19.3009 2.85053 19.0879 3.15092 18.9909C5.30117 18.5504 7.62385 18.1795 10.1189 17.8317C10.963 17.6953 11.8128 17.6101 12.6648 17.5767C13.9065 17.5148 15.1496 17.6554 16.3567 17.994C17.0679 18.1805 17.7514 18.4851 18.3852 18.8982L18.5171 19.0025L19.0952 19.4314C19.5481 19.7914 19.858 20.3414 19.9574 20.9615"
            fill="#315DDB"
          />
        </svg>

        <span className="text-base font-semibold text-blueVirtual">
          {t("LOGIN")}
        </span>
      </div>
    </div>
  );
};
export default Header;
