import { useLayoutEffect } from "react";
import { Routes } from "react-router";
import { MemoryRouter, Route } from "react-router-dom";
import Home from "./Home";
import Login from "./Login";
import Reset from "./Reset";
import Signup from "./Signup";
import CookieConsent from "react-cookie-consent";
import { useTranslation } from "react-i18next";

function App() {
  const { t, i18n } = useTranslation();
  return (
    <>
      <CookieConsent
        location="bottom"
        buttonText={t("ACCEPT")}
        cookieName="cookie_virtualfys"
        style={{ background: "#315ddb" }}
        buttonStyle={{ background: "#FBAD56", fontSize: "13px" }}
        expires={150}
      >
        {t("COOKIE_TEXT")}{" "}
      </CookieConsent>
      <MemoryRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/reset" element={<Reset />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </MemoryRouter>
    </>
  );
}

export default App;
