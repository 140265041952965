import { useSnackbar } from "notistack";
import { SyntheticEvent, useEffect, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Reset = () => {
  const { t, i18n } = useTranslation();
  const [rePassword, setRePassword] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const { enqueueSnackbar } = useSnackbar();
  let navigate = useNavigate();

  const [resetToken, setResetToken] = useState<string>("");

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const resetPasswordToken = query.get("token");

    console.log(resetPasswordToken);

    if (resetPasswordToken !== null) {
      setResetToken(resetPasswordToken);
    }
  }, []);

  function onSubmit(event: SyntheticEvent) {
    event.preventDefault();
    if (password !== rePassword) {
      enqueueSnackbar(t("PASSWORD_NOMATCH"), {
        variant: "error",
      });
    } else {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      fetch("https://cms-fys.imascono.com/auth/password/reset", {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          token: resetToken,
          password: password,
        }),
      }).then(async (response: Response) => {
        switch (response.status) {
          case 204:
            enqueueSnackbar(t("PASSWORD_CHANGED"), {
              variant: "success",
            });
            navigate("/login");
            break;
          default:
            enqueueSnackbar(t("NO_PASSWORD_CHANGED"), {
              variant: "error",
            });
            break;
        }
      });
    }

    return;
    // var myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/json");

    // fetch("https://cms-fys.imascono.com/auth/login", {
    //   method: "POST",
    //   headers: myHeaders,
    //   body: JSON.stringify({
    //     email: email,
    //     password: password,
    //   }),
    // }).then(async (response: Response) => {
    //   switch (response.status) {
    //     case 200:
    //       let responseJSON = await response.json();
    //       window.localStorage.setItem(
    //         "access_token",
    //         responseJSON.data.access_token
    //       );
    //       window.localStorage.setItem(
    //         "refresh_token",
    //         responseJSON.data.refresh_token
    //       );
    //       window.open(virtualSpacePath, "_self");
    //       break;
    //     default:
    //       enqueueSnackbar(t("WRONG_EMAIL"), {
    //         variant: "error",
    //       });
    //       break;
    //   }
    // });
  }

  return (
    <div className="">
      <div className="flex w-full justify-start">
        <div
          className="flex flex-col ml-6 mt-4 cursor-pointer hover:scale-105"
          onClick={() => {
            navigate("/login");
          }}
        >
          <svg
            width="48"
            height="44"
            viewBox="0 0 48 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.4161 21.6433C11.4161 21.4944 11.6705 21.3455 12.1792 21.1921C12.6385 21.0499 13.115 20.974 13.5949 20.9664C16.7905 20.9378 19.8135 20.8626 22.664 20.7408C25.5145 20.6189 28.2987 20.4685 31.0165 20.2895C31.5083 20.2508 32.0004 20.3598 32.4322 20.6031C32.7856 20.7747 33.0588 21.081 33.1931 21.4561C33.2409 21.6081 33.256 21.7688 33.2373 21.9273C33.2186 22.0858 33.1666 22.2383 33.0847 22.3744C32.9166 22.6881 32.5892 22.8911 32.1048 22.9791C29.3442 23.3086 26.3669 23.5628 23.1728 23.7418C19.9787 23.9212 16.7765 23.8918 13.5861 23.6538C13.339 23.6074 13.0962 23.5395 12.8605 23.4507C12.5789 23.3505 12.3107 23.2148 12.062 23.0468C11.8278 22.8901 11.6294 22.6839 11.4803 22.4421C11.4118 22.3223 11.3706 22.1883 11.3595 22.0501C11.3484 21.9119 11.3677 21.7729 11.4161 21.6433Z"
              fill="#FBAD56"
            />
            <path
              d="M2.86429 21.5029C2.93286 21.3697 3.1983 21.3449 3.66503 21.4262C4.08314 21.4931 4.48841 21.6263 4.86613 21.821C7.37304 23.1343 9.77157 24.3377 12.0617 25.4313C14.3518 26.5249 16.5985 27.5591 18.8016 28.5338C19.2081 28.7083 19.547 29.0144 19.766 29.4048C19.9664 29.723 20.04 30.1072 19.9718 30.4789C19.9069 30.7925 19.7253 31.0684 19.4652 31.2483C19.3139 31.3523 19.1403 31.4178 18.9591 31.4394C18.7778 31.461 18.5941 31.438 18.4234 31.3724C16.1155 30.5059 13.6728 29.4823 11.0951 28.3014C8.51807 27.1213 6.02889 25.7511 3.64733 24.2015C3.47292 24.0577 3.31354 23.8959 3.17175 23.7187C2.99474 23.5089 2.84596 23.2759 2.72936 23.0259C2.61668 22.7823 2.55634 22.5169 2.5524 22.2475C2.55192 22.1082 2.57935 21.9704 2.63298 21.8423C2.68661 21.7143 2.76532 21.5988 2.86429 21.5029Z"
              fill="#FBAD56"
            />
            <path
              d="M19.8789 14.0817C19.8752 14.3514 19.8141 14.617 19.6997 14.8602C19.5831 15.1101 19.4344 15.3431 19.2573 15.5529C19.1161 15.7307 18.9567 15.8925 18.7818 16.0358C16.4 17.5849 13.9108 18.9552 11.334 20.1357C8.75485 21.3165 6.31282 22.3402 4.00794 23.2066C3.83676 23.2721 3.65277 23.2949 3.4712 23.2734C3.28963 23.2518 3.11572 23.1864 2.96388 23.0825C2.70496 22.9018 2.52432 22.6262 2.45955 22.3131C2.39221 21.9411 2.46658 21.557 2.66748 21.2391C2.88567 20.8488 3.22384 20.5427 3.62969 20.3681C5.83135 19.3918 8.07503 18.3569 10.3607 17.2633C12.6465 16.1697 15.0443 14.9662 17.5541 13.653C17.9341 13.4562 18.3426 13.3228 18.7641 13.2581C19.2286 13.1769 19.4962 13.2017 19.5648 13.3349C19.664 13.4311 19.743 13.547 19.797 13.6754C19.851 13.8038 19.8789 13.942 19.8789 14.0817Z"
              fill="#FBAD56"
            />
          </svg>
          <span className="font-NanumBrushScript text-yellowVirtual text-2xl">
            {t("BACK")}
          </span>
        </div>
      </div>
      <div className="flex justify-center items-center gap-3 mt-8 mr-8">
        <span className="text-base font-semibold text-blueVirtual uppercase">
          {t("NEW_PASSWORD")}
        </span>
      </div>

      <div className="w-full flex flex-col justify-center items-center">
        <form
          onSubmit={onSubmit}
          className="px-12 flex flex-col justify-center mt-16 md:w-1/3"
        >
          {/* EMAIL */}
          <label className="block">{t("PASSWORD")}</label>
          <div className="px-2 mt-3 flex justify-center items-center flex-col">
            <input
              type="password"
              required
              placeholder="*************"
              className="font-light text-[#2d2d2d] px-6 w-full outline-none"
              onChange={(event) => setRePassword(event.target.value)}
            />
            <img src="./images/hline_yellow.png" className="w-full" />
          </div>
          {/* PASSWORD */}
          <label className="block mt-10">{t("RE_PASSWORD")}</label>
          <div className="px-2 mt-3 flex justify-center items-center flex-col">
            <input
              type="password"
              required
              placeholder="*************"
              className="font-light text-[#2d2d2d] px-6 w-full outline-none"
              onChange={(event) => setPassword(event.target.value)}
            />
            <img src="./images/hline_yellow.png" className="w-full" />
          </div>
          <div className="flex justify-center items-center mt-8">
            <button
              type="submit"
              className="mt-12 px-2 flex justify-center items-center gap-4 bg-blueVirtual text-white uppercase text-center font-semibold py-4 leading-5 rounded-md w-auto cursor-pointer hover:scale-105"
            >
              {t("RESET")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Reset;
