import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
i18n
  .use(initReactI18next)
  .use(LanguageDetector) // passes i18n down to react-i18next
  .init({
    resources: {
      "es-ES": {
        translation: {
          BACK: "Atras",
          "3D_COLLABORATIVE": "SALA 3D COLABORATIVA",
          ENJOY_EXPERIENCE: "Explora los diferentes",
          ENJOY_EXPERIENCE_2: "mundos culturales",
          ENTER_GUEST: "Entrar como invitado",
          DONT_HAVE_ACCOUNT: "Aún no tienes una cuenta?",
          SIGNUP: "Regístrate",
          LOGIN: "Iniciar sesión",
          EMAIL: "Email",
          PASSWORD: "Contraseña",
          NEW_PASSWORD: "Nueva contraseña",
          RE_PASSWORD: "Vuelva a introducir la contraseña",
          FORGOT_PASSWORD: "Contraseña olvidada?",
          CREATE_ACCOUNT: "Crear cuenta",
          NAME: "Nombre",
          SURNAME: "Apellidos",
          COUNTRY: "País",
          CITY: "Ciudad",
          CONFIRM_PASSWORD: "Confirmar contraseña",
          CREATE: "Crear",
          REGISTER_COMPLETED: "Registro completado",
          UNABLE_REGISTER: "No se ha podido registrar",
          USER_EXIST: "El usuario ya existe",
          PASSWORD_NOMATCH: "Las contraseñas no coinciden",
          WRONG_EMAIL: "Email o contraseña incorrectos",
          RESET: "Resetear",
          PASSWORD_CHANGED: "Contraseña cambiada satisfactoriamente",
          SEE_EMAIL: "Revisa tu bandeja de entrada",
          NO_PASSWORD_CHANGED: "No se ha podido reestablecer su contraseña",
          SEND_FORGOT_PASSWORD:
            "Ponga su correo y pulse en contraseña olvidada",
          I_READ: "He leído y acepto los",
          TERMS: "términos",
          AND: "y",
          CONDITIONS: "condiciones",
          PRIVACY_POLICY: "Política de privacidad",
          COOKIES: "Cookies",
          LEGAL_NOTICE: "Aviso legal",
          COOKIE_TEXT:
            "Esta web usa cookies para mejorar la experiencia de usuario.",
          ACCEPT: "Aceptar",
        },
      },
      "en-US": {
        translation: {
          BACK: "Back",
          "3D_COLLABORATIVE": "3D COLLABORATIVE ROOM",
          ENJOY_EXPERIENCE: "Explore different",
          ENJOY_EXPERIENCE_2: "cultural worlds",
          ENTER_GUEST: "Enter as guest",
          DONT_HAVE_ACCOUNT: "Don't you have an account yet?",
          SIGNUP: "Sign up",
          LOGIN: "Log in",
          EMAIL: "Email",
          PASSWORD: "Password",
          NEW_PASSWORD: "New password",
          RE_PASSWORD: "Put the password again",
          FORGOT_PASSWORD: "Forgot password?",
          CREATE_ACCOUNT: "Create account",
          NAME: "Name",
          SURNAME: "Surname",
          COUNTRY: "Country",
          CITY: "City",
          CONFIRM_PASSWORD: "Confirm password",
          CREATE: "Create",
          REGISTER_COMPLETED: "Registration completed",
          UNABLE_REGISTER: "Unable to register",
          USER_EXIST: "The user already exists",
          PASSWORD_NOMATCH: "Passwords do not match",
          WRONG_EMAIL: "Incorrect email or password",
          RESET: "Reset",
          PASSWORD_CHANGED: "Password changed",
          SEE_EMAIL: "Check your inbox",
          NO_PASSWORD_CHANGED: "Password has not been reestablished",
          SEND_FORGOT_PASSWORD:
            "Enter your email address and click on forgot password",
          I_READ: "I've read and accept the",
          TERMS: "terms",
          AND: "and",
          CONDITIONS: "conditions",
          PRIVACY_POLICY: "Privacy Policy",
          COOKIES: "Cookies",
          LEGAL_NOTICE: "Legal Notice",
          COOKIE_TEXT:
            "This website uses cookies to enhance the user experience.",
          ACCEPT: "Accept",
        },
      },
      "it-IT": {
        translation: {
          BACK: "Indietro",
          "3D_COLLABORATIVE": "SALA 3D COLLABORATIVA",
          ENJOY_EXPERIENCE: "Esplorare mondi",
          ENJOY_EXPERIENCE_2: "culturali diversi",
          ENTER_GUEST: "Entrare come ospite",
          DONT_HAVE_ACCOUNT: "Non avete ancora un account?",
          SIGNUP: "Registro",
          LOGIN: "Accedi",
          EMAIL: "Email",
          PASSWORD: "Password",
          NEW_PASSWORD: "Nuova password",
          RE_PASSWORD: "Metti di nuovo la password",
          FORGOT_PASSWORD: "Password dimenticata?",
          CREATE_ACCOUNT: "Creare un account",
          NAME: "Nome",
          SURNAME: "Cognome",
          COUNTRY: "Paese",
          CITY: "Città",
          CONFIRM_PASSWORD: "Confermare la password",
          CREATE: "Creare",
          REGISTER_COMPLETED: "Registrazione completata",
          UNABLE_REGISTER: "Impossibile registrarsi",
          USER_EXIST: "L'utente esiste già",
          PASSWORD_NOMATCH: "Le password non corrispondono",
          WRONG_EMAIL: "Email o password erratas",
          RESET: "Ripristina",
          PASSWORD_CHANGED: "Password modificata",
          SEE_EMAIL: "Controlla la tua casella di posta",
          NO_PASSWORD_CHANGED: "Nessuna password non è stata ristabilita",
          SEND_FORGOT_PASSWORD:
            "Inserite il vostro indirizzo e-mail e cliccate su Password dimenticata",
          I_READ: "Ho letto e accetto i ",
          TERMS: "termini",
          AND: "e le",
          CONDITIONS: "condizioni",
          PRIVACY_POLICY: "Informativa sulla privacy",
          COOKIES: "I cookie",
          LEGAL_NOTICE: "Nota legale",
          COOKIE_TEXT:
            "Questo sito web utilizza i cookie per migliorare l'esperienza dell'utente.",
          ACCEPT: "Accettare",
        },
      },
      "sk-SK": {
        translation: {
          BACK: "Späť",
          "3D_COLLABORATIVE": "KOLABORATÍVNA 3D MIESTNOSŤ",
          ENJOY_EXPERIENCE: "Preskúmajte iné",
          ENJOY_EXPERIENCE_2: "kultúrne svety",
          ENTER_GUEST: "Prihlásiť sa ako hosť",
          DONT_HAVE_ACCOUNT: "Ešte nemáte účet?",
          SIGNUP: "Zaregistrujte sa",
          LOGIN: "Prihlásiť sa",
          EMAIL: "E-mail",
          PASSWORD: "Heslo",
          NEW_PASSWORD: "Nové heslo",
          RE_PASSWORD: "Znovu zadať heslo",
          FORGOT_PASSWORD: "Zabudli ste heslo?",
          CREATE_ACCOUNT: "Vytvoriť účet",
          NAME: "Krstné meno",
          SURNAME: "Priezvisko",
          COUNTRY: "Krajina",
          CITY: "Mesto",
          CONFIRM_PASSWORD: "Potvrdiť heslo",
          CREATE: "Vytvoriť",
          REGISTER_COMPLETED: "Registrácia dokončená",
          UNABLE_REGISTER: "Nepodarilo sa zaregistrovať",
          USER_EXIST: "Používateľ už existuje",
          PASSWORD_NOMATCH: "Heslá sa nezhodujú",
          WRONG_EMAIL: "Nesprávny e-mail alebo heslo",
          RESET: "Reset",
          PASSWORD_CHANGED: "Heslo sa uspokojivo zmenilo",
          SEE_EMAIL: "Skontrolujte svoju schránku",
          NO_PASSWORD_CHANGED: "Nepodarilo sa obnoviť heslo",
          SEND_FORGOT_PASSWORD:
            "Zadajte svoju e-mailovú adresu a kliknite na zabudnuté heslo",
          I_READ: "Prečítal som a prijímam ",
          TERMS: "podmienky",
          AND: "a",
          CONDITIONS: "podmienky",
          PRIVACY_POLICY: "Zásady ochrany osobných údajov",
          COOKIES: "Cookies",
          LEGAL_NOTICE: "Právne upozornenie",
          COOKIE_TEXT:
            "Táto webová stránka používa súbory cookie na zlepšenie používateľského zážitku.",
          ACCEPT: "Prijať",
        },
      },
      "pt-BR": {
        translation: {
          BACK: "Voltar",
          "3D_COLLABORATIVE": "SALA COLABORATIVA 3D",
          ENJOY_EXPERIENCE: "Explorar diferentes",
          ENJOY_EXPERIENCE_2: "mundos culturais",
          ENTER_GUEST: "Entre como convidado",
          DONT_HAVE_ACCOUNT: "Ainda não tem uma conta?",
          SIGNUP: "Registe-se",
          LOGIN: "Iniciar sessão",
          EMAIL: "Email",
          PASSWORD: "Senha",
          NEW_PASSWORD: "Nova senha",
          RE_PASSWORD: "Coloque a senha novamente",
          FORGOT_PASSWORD: "Esqueceu-se da palavra-passe?",
          CREATE_ACCOUNT: "Criar uma conta",
          NAME: "Primeiro nome",
          SURNAME: "Apelido",
          COUNTRY: "País",
          CITY: "Cidade",
          CONFIRM_PASSWORD: "Confirmar palavra-passe",
          CREATE: "Criar",
          REGISTER_COMPLETED: "Registo concluído",
          UNABLE_REGISTER: "Impossibilidade de registo",
          USER_EXIST: "O usuário já existe",
          PASSWORD_NOMATCH: "As palavras-passe não coincidem",
          WRONG_EMAIL: "Correio electrónico ou palavra-passe incorrectos",
          RESET: "Reiniciar",
          PASSWORD_CHANGED: "Senha alterada como a senha",
          SEE_EMAIL: "Verifique sua caixa de entrada",
          NO_PASSWORD_CHANGED: "Nenhuma senha não foi restabelecida",
          SEND_FORGOT_PASSWORD:
            "Digite seu endereço de e-mail e clique na senha esquecida",
          I_READ: "Eu li e aceito os ",
          TERMS: "termos",
          AND: "e",
          CONDITIONS: "Condições",
          PRIVACY_POLICY: "Política de privacidade",
          COOKIES: "Biscoitos",
          LEGAL_NOTICE: "Aviso legal",
          COOKIE_TEXT:
            "Este website utiliza cookies para melhorar a experiência do utilizador",
          ACCEPT: "Aceitar",
        },
      },
    },
    // lng: "es", // if you're using a language detector, do not define the lng option
    fallbackLng: "es-ES",
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });
