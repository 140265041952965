import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t, i18n } = useTranslation();
  return (
    <div
      style={{ boxShadow: "0px 14px 20px 16px rgba(49,93,219,1)" }}
      className="bg-[#315DDB] max-h-[3rem] z-50 w-full text-white text-xs fixed bottom-0 flex flex-row justify-center items-center py-2 space-x-4"
    >
      <a
        className="cursor-pointer hover:underline"
        href={
          i18n.language === "es-ES"
            ? "https://commission.europa.eu/legal-notice_es"
            : i18n.language === "en-US"
            ? "https://commission.europa.eu/legal-notice_en"
            : i18n.language === "it-IT"
            ? "https://commission.europa.eu/legal-notice_it"
            : i18n.language === "sk-SK"
            ? "https://commission.europa.eu/legal-notice_sk"
            : i18n.language === "pt-BR"
            ? "https://commission.europa.eu/legal-notice_pt"
            : "https://commission.europa.eu/legal-notice_en"
        }
        id="i18n_LEGAL_NOTICE"
      >
        {t("LEGAL_NOTICE")}
      </a>
      <div
        style={{
          borderLeft: "1px solid white",
          height: "20px",
        }}
      ></div>
      <a
        className="cursor-pointer hover:underline"
        href={
          i18n.language === "es-ES"
            ? "https://commission.europa.eu/privacy-policy-websites-managed-european-commission_es"
            : i18n.language === "en-US"
            ? "https://commission.europa.eu/privacy-policy-websites-managed-european-commission_en"
            : i18n.language === "it-IT"
            ? "https://commission.europa.eu/privacy-policy-websites-managed-european-commission_it"
            : i18n.language === "sk-SK"
            ? "https://commission.europa.eu/privacy-policy-websites-managed-european-commission_sk"
            : i18n.language === "pt-BR"
            ? "https://commission.europa.eu/privacy-policy-websites-managed-european-commission_pt"
            : "https://commission.europa.eu/privacy-policy-websites-managed-european-commission_en"
        }
        id="i18n_PRIVACY_POLICY"
      >
        {t("PRIVACY_POLICY")}
      </a>
      <div
        style={{
          borderLeft: "1px solid white",
          height: "20px",
        }}
      ></div>
      <a
        className="cursor-pointer hover:underline"
        href={
          i18n.language === "es-ES"
            ? "https://commission.europa.eu/cookies-policy_es"
            : i18n.language === "en-US"
            ? "https://commission.europa.eu/cookies-policy_en"
            : i18n.language === "it-IT"
            ? "https://commission.europa.eu/cookies-policy_it"
            : i18n.language === "sk-SK"
            ? "https://commission.europa.eu/cookies-policy_sk"
            : i18n.language === "pt-BR"
            ? "https://commission.europa.eu/cookies-policy_pt"
            : "https://commission.europa.eu/cookies-policy_en"
        }
        id="i18n_COOKIES"
      >
        {t("COOKIES")}
      </a>
    </div>
  );
};

export default Footer;
