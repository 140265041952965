import { useSnackbar } from "notistack";
import { SyntheticEvent, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";

const Signup = () => {
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [rePassword, setRePassword] = useState<string>("");
  let navigate = useNavigate();

  function checkValidation() {
    if (password !== rePassword) {
      enqueueSnackbar(t("PASSWORD_NOMATCH"), {
        variant: "warning",
      });
      return false;
    }

    return true;
  }

  function onSubmit(event: SyntheticEvent) {
    event.preventDefault();
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    if (!checkValidation()) return;

    fetch("https://cms-fys.imascono.com/users", {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        email: email,
        password: password,
        location: country.concat(", ", city),
        first_name: firstName,
        last_name: lastName,
      }),
    }).then(async (response: Response) => {
      console.log(response);
      switch (response.status) {
        case 204:
          enqueueSnackbar(t("REGISTER_COMPLETED"), {
            variant: "success",
          });
          navigate("/login");
          break;
        case 400:
          enqueueSnackbar(t("USER_EXIST"), {
            variant: "error",
          });
          break;
        default:
          enqueueSnackbar(t("UNABLE_REGISTER"), {
            variant: "error",
          });
          break;
      }
    });
  }

  return (
    <>
      <div className="">
        <div className="flex w-full justify-start">
          <div
            className="flex flex-col ml-6 mt-4 cursor-pointer hover:scale-105"
            onClick={() => {
              navigate(-1);
            }}
          >
            <svg
              width="48"
              height="44"
              viewBox="0 0 48 44"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.4161 21.6433C11.4161 21.4944 11.6705 21.3455 12.1792 21.1921C12.6385 21.0499 13.115 20.974 13.5949 20.9664C16.7905 20.9378 19.8135 20.8626 22.664 20.7408C25.5145 20.6189 28.2987 20.4685 31.0165 20.2895C31.5083 20.2508 32.0004 20.3598 32.4322 20.6031C32.7856 20.7747 33.0588 21.081 33.1931 21.4561C33.2409 21.6081 33.256 21.7688 33.2373 21.9273C33.2186 22.0858 33.1666 22.2383 33.0847 22.3744C32.9166 22.6881 32.5892 22.8911 32.1048 22.9791C29.3442 23.3086 26.3669 23.5628 23.1728 23.7418C19.9787 23.9212 16.7765 23.8918 13.5861 23.6538C13.339 23.6074 13.0962 23.5395 12.8605 23.4507C12.5789 23.3505 12.3107 23.2148 12.062 23.0468C11.8278 22.8901 11.6294 22.6839 11.4803 22.4421C11.4118 22.3223 11.3706 22.1883 11.3595 22.0501C11.3484 21.9119 11.3677 21.7729 11.4161 21.6433Z"
                fill="#FBAD56"
              />
              <path
                d="M2.86429 21.5029C2.93286 21.3697 3.1983 21.3449 3.66503 21.4262C4.08314 21.4931 4.48841 21.6263 4.86613 21.821C7.37304 23.1343 9.77157 24.3377 12.0617 25.4313C14.3518 26.5249 16.5985 27.5591 18.8016 28.5338C19.2081 28.7083 19.547 29.0144 19.766 29.4048C19.9664 29.723 20.04 30.1072 19.9718 30.4789C19.9069 30.7925 19.7253 31.0684 19.4652 31.2483C19.3139 31.3523 19.1403 31.4178 18.9591 31.4394C18.7778 31.461 18.5941 31.438 18.4234 31.3724C16.1155 30.5059 13.6728 29.4823 11.0951 28.3014C8.51807 27.1213 6.02889 25.7511 3.64733 24.2015C3.47292 24.0577 3.31354 23.8959 3.17175 23.7187C2.99474 23.5089 2.84596 23.2759 2.72936 23.0259C2.61668 22.7823 2.55634 22.5169 2.5524 22.2475C2.55192 22.1082 2.57935 21.9704 2.63298 21.8423C2.68661 21.7143 2.76532 21.5988 2.86429 21.5029Z"
                fill="#FBAD56"
              />
              <path
                d="M19.8789 14.0817C19.8752 14.3514 19.8141 14.617 19.6997 14.8602C19.5831 15.1101 19.4344 15.3431 19.2573 15.5529C19.1161 15.7307 18.9567 15.8925 18.7818 16.0358C16.4 17.5849 13.9108 18.9552 11.334 20.1357C8.75485 21.3165 6.31282 22.3402 4.00794 23.2066C3.83676 23.2721 3.65277 23.2949 3.4712 23.2734C3.28963 23.2518 3.11572 23.1864 2.96388 23.0825C2.70496 22.9018 2.52432 22.6262 2.45955 22.3131C2.39221 21.9411 2.46658 21.557 2.66748 21.2391C2.88567 20.8488 3.22384 20.5427 3.62969 20.3681C5.83135 19.3918 8.07503 18.3569 10.3607 17.2633C12.6465 16.1697 15.0443 14.9662 17.5541 13.653C17.9341 13.4562 18.3426 13.3228 18.7641 13.2581C19.2286 13.1769 19.4962 13.2017 19.5648 13.3349C19.664 13.4311 19.743 13.547 19.797 13.6754C19.851 13.8038 19.8789 13.942 19.8789 14.0817Z"
                fill="#FBAD56"
              />
            </svg>
            <span className="font-NanumBrushScript text-yellowVirtual text-2xl">
              {t("BACK")}
            </span>
          </div>
        </div>
        <div className="flex justify-center items-center gap-3 mt-8 cursor-pointer hover:scale-105">
          <span className="text-base font-semibold text-blueVirtual uppercase">
            {t("CREATE_ACCOUNT")}
          </span>
        </div>

        <div className="w-full flex flex-col justify-center items-center">
          <form
            onSubmit={onSubmit}
            className="px-12 flex flex-col justify-center mt-12 md:w-1/2"
          >
            <div className="md:grid md:grid-cols-2">
              {/* NAME */}
              <div>
                <label className="block">{t("NAME")}</label>
                <div className="px-2 mt-3 flex justify-center items-center flex-col">
                  <input
                    id="firstName"
                    type="text"
                    required
                    placeholder="James"
                    autoComplete="name"
                    className="font-light text-[#2d2d2d] px-6 w-full outline-none"
                    onChange={(event) => setFirstName(event.target.value)}
                  />
                  <img
                    src="./images/hline_yellow.png"
                    className="w-full h-[0.27rem]"
                  />
                </div>
              </div>
              {/* SURNAME */}
              <div>
                <label className="block mt-8 md:mt-0">{t("SURNAME")}</label>
                <div className="px-2 mt-3 flex justify-center items-center flex-col">
                  <input
                    type="text"
                    required
                    placeholder="Wilson"
                    autoComplete="surname"
                    className="font-light text-[#2d2d2d] px-6 w-full outline-none"
                    onChange={(event) => setLastName(event.target.value)}
                  />
                  <img
                    src="./images/hline_yellow.png"
                    className="w-full h-[0.27rem]"
                  />
                </div>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2">
              {/* COUNTRY */}
              <div>
                <label className="block mt-8">{t("COUNTRY")}</label>
                <div className="px-2 mt-3 flex justify-center items-center flex-col">
                  <input
                    type="text"
                    required
                    placeholder="Spain"
                    autoComplete="country"
                    className="font-light text-[#2d2d2d] px-6 w-full outline-none"
                    onChange={(event) => setCountry(event.target.value)}
                  />
                  <img
                    src="./images/hline_yellow.png"
                    className="w-full h-[0.27rem]"
                  />
                </div>
              </div>
              {/* CITY */}
              <div>
                <label className="block mt-8">{t("CITY")}</label>
                <div className="px-2 mt-3 flex justify-center items-center flex-col">
                  <input
                    type="text"
                    required
                    placeholder="Zaragoza"
                    autoComplete="city"
                    className="font-light text-[#2d2d2d] px-6 w-full outline-none"
                    onChange={(event) => setCity(event.target.value)}
                  />
                  <img
                    src="./images/hline_yellow.png"
                    className="w-full h-[0.27rem]"
                  />
                </div>
              </div>
            </div>
            {/* EMAIL */}
            <label className="block mt-8">{t("EMAIL")}</label>
            <div className="px-2 mt-3 flex justify-center items-center flex-col">
              <input
                type="email"
                required
                placeholder="user@email.com"
                autoComplete="email"
                className="font-light text-[#2d2d2d] px-6 w-full outline-none"
                onChange={(event) => setEmail(event.target.value)}
              />
              <img
                src="./images/hline_yellow.png"
                className="w-full h-[0.27rem]"
              />
            </div>
            {/* PASSWORD */}
            <label className="block mt-8">{t("PASSWORD")}</label>
            <div className="px-2 mt-3 flex justify-center items-center flex-col">
              <input
                type="password"
                required
                placeholder="*************"
                autoComplete="new-password"
                className="font-light text-[#2d2d2d] px-6 w-full outline-none"
                onChange={(event) => setPassword(event.target.value)}
              />
              <img
                src="./images/hline_yellow.png"
                className="w-full h-[0.27rem]"
              />
            </div>
            {/* PASSWORD */}
            <label className="block mt-8">{t("CONFIRM_PASSWORD")}</label>
            <div className="px-2 mt-3 flex justify-center items-center flex-col">
              <input
                type="password"
                required
                placeholder="*************"
                autoComplete="new-password"
                className="font-light text-[#2d2d2d] px-6 w-full outline-none"
                onChange={(event) => setRePassword(event.target.value)}
              />
              <img
                src="./images/hline_yellow.png"
                className="w-full h-[0.27rem]"
              />
            </div>
            <div className="flex items-center mt-4">
              <input
                required
                id="checked-checkbox"
                type="checkbox"
                value=""
                className="w-4 h-4"
              />
              <label htmlFor="checked-checkbox" className="ml-2">
                {t("I_READ")}{" "}
                <a
                  className="cursor-pointer underline hover:no-underline"
                  target={"_blank"}
                  href={
                    i18n.language === "es-ES"
                      ? "https://commission.europa.eu/legal-notice_es"
                      : i18n.language === "en-US"
                      ? "https://commission.europa.eu/legal-notice_en"
                      : i18n.language === "it-IT"
                      ? "https://commission.europa.eu/legal-notice_it"
                      : i18n.language === "sk-SK"
                      ? "https://commission.europa.eu/legal-notice_sk"
                      : i18n.language === "pt-BR"
                      ? "https://commission.europa.eu/legal-notice_pt"
                      : "https://commission.europa.eu/legal-notice_en"
                  }
                >
                  {t("TERMS")}
                </a>{" "}
                {t("AND")}{" "}
                <a
                  className="cursor-pointer underline hover:no-underline"
                  target={"_blank"}
                  href={
                    i18n.language === "es-ES"
                      ? "https://commission.europa.eu/privacy-policy-websites-managed-european-commission_es"
                      : i18n.language === "en-US"
                      ? "https://commission.europa.eu/privacy-policy-websites-managed-european-commission_en"
                      : i18n.language === "it-IT"
                      ? "https://commission.europa.eu/privacy-policy-websites-managed-european-commission_it"
                      : i18n.language === "sk-SK"
                      ? "https://commission.europa.eu/privacy-policy-websites-managed-european-commission_sk"
                      : i18n.language === "pt-BR"
                      ? "https://commission.europa.eu/privacy-policy-websites-managed-european-commission_pt"
                      : "https://commission.europa.eu/privacy-policy-websites-managed-european-commission_en"
                  }
                >
                  {t("CONDITIONS")}
                </a>
              </label>
            </div>

            <div className="flex justify-center items-center mt-4 mb-4">
              <button
                onSubmit={onSubmit}
                type="submit"
                className="mt-12 mb-[3rem] flex justify-center items-center gap-4 bg-blueVirtual text-white uppercase text-center font-semibold py-4 leading-5 rounded-md w-[10rem] cursor-pointer hover:scale-105"
              >
                {t("CREATE")}{" "}
                <svg
                  width="48"
                  height="19"
                  viewBox="0 0 48 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M38.8937 9.85768C38.8937 9.98263 38.4404 10.1076 37.5339 10.2363C36.7156 10.3556 35.8666 10.4193 35.0116 10.4256C29.3179 10.4496 23.9316 10.5127 18.8528 10.615C13.7739 10.7172 8.81325 10.8434 3.97085 10.9936C3.09461 11.0261 2.21776 10.9346 1.44849 10.7304C0.81877 10.5865 0.33191 10.3295 0.092729 10.0148C0.00744188 9.88728 -0.0194584 9.7524 0.0138735 9.61941C0.0472055 9.48641 0.139981 9.35845 0.285846 9.24429C0.585376 8.98113 1.16867 8.81075 2.03179 8.73691C6.95038 8.4605 12.2552 8.2472 17.9463 8.09701C23.6374 7.94649 29.3428 7.97115 35.0274 8.17084C35.4676 8.20977 35.9001 8.26677 36.3201 8.34123C36.8218 8.42533 37.2998 8.53917 37.7428 8.68011C38.1601 8.81159 38.5136 8.98462 38.7794 9.18749C38.9013 9.28803 38.9748 9.4004 38.9946 9.51637C39.0144 9.63233 38.9799 9.74895 38.8937 9.85768Z"
                    fill="white"
                  />
                  <path
                    d="M47.1362 10.2549C47.0676 10.3881 46.8022 10.4129 46.3355 10.3317C45.9174 10.2648 45.5121 10.1315 45.1344 9.93679C42.6274 8.62356 40.2289 7.42014 37.9388 6.32653C35.6486 5.23292 33.402 4.19874 31.1989 3.22397C30.7924 3.04952 30.4535 2.74345 30.2344 2.35299C30.0341 2.03484 29.9605 1.65062 30.0287 1.27894C30.0936 0.965263 30.2752 0.68946 30.5353 0.509505C30.6866 0.405529 30.8602 0.34001 31.0414 0.31842C31.2227 0.296831 31.4064 0.319798 31.5771 0.385402C33.885 1.25186 36.3277 2.27552 38.9054 3.45638C41.4824 4.63647 43.9716 6.00674 46.3532 7.55627C46.5276 7.70008 46.6869 7.8619 46.8287 8.03915C47.0058 8.24895 47.1545 8.48191 47.2711 8.73186C47.3838 8.97553 47.4441 9.24094 47.4481 9.51033C47.4486 9.64957 47.4211 9.78744 47.3675 9.91548C47.3139 10.0435 47.2352 10.159 47.1362 10.2549Z"
                    fill="white"
                  />
                  <path
                    d="M30.1211 17.6761C30.1248 17.4064 30.1859 17.1408 30.3003 16.8976C30.4169 16.6477 30.5656 16.4147 30.7427 16.2049C30.8839 16.0271 31.0433 15.8653 31.2182 15.722C33.6 14.1729 36.0892 12.8027 38.666 11.6221C41.2451 10.4413 43.6872 9.41763 45.9921 8.55117C46.1632 8.48574 46.3472 8.46287 46.5288 8.48445C46.7104 8.50603 46.8843 8.57144 47.0361 8.67527C47.295 8.85598 47.4757 9.13157 47.5405 9.44471C47.6078 9.81667 47.5334 10.2008 47.3325 10.5188C47.1143 10.9091 46.7762 11.2152 46.3703 11.3897C44.1687 12.366 41.925 13.4009 39.6393 14.4946C37.3535 15.5882 34.9557 16.7916 32.4459 18.1048C32.0659 18.3016 31.6574 18.435 31.2359 18.4997C30.7714 18.5809 30.5038 18.5561 30.4352 18.423C30.336 18.3267 30.257 18.2108 30.203 18.0824C30.149 17.954 30.1211 17.8158 30.1211 17.6761Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Signup;
