import { useEffect, useLayoutEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";

const Home = () => {
  let navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [virtualSpacePath, setVirtualSpacePath] = useState<string>(
    "https://virtualfys.com/Broadcast"
  );

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const resetPasswordToken = query.get("token");

    console.log(resetPasswordToken);

    if (resetPasswordToken !== null) {
      navigate("/reset");
    }
  }, []);

  useLayoutEffect(() => {
    let src = new URLSearchParams(window.location.search).get("src");
    if (src !== null) {
      switch (src) {
        case "Goya":
          {
            setVirtualSpacePath("https://virtualfys.com/Goya");
          }
          break;
        case "Volpedo":
          {
            setVirtualSpacePath("https://virtualfys.com/Volpedo");
          }
          break;
        case "Lubovna":
          {
            setVirtualSpacePath("https://virtualfys.com/Lubovna");
          }
          break;
        case "Portugal":
          {
            setVirtualSpacePath("https://virtualfys.com/Portugal");
          }
          break;
        case "Broadcast":
          {
            setVirtualSpacePath("https://virtualfys.com/Broadcast");
          }
          break;
        default:
          setVirtualSpacePath("https://virtualfys.com/Broadcast");
      }
    } else {
      if (window.localStorage.getItem("refresh_token")) {
        checkUser();
      }
    }
  }, []);

  function checkUser() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    fetch("https://cms-fys.imascono.com/auth/refresh", {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        refresh_token: window.localStorage.getItem("refresh_token"),
        mode: "json",
      }),
    }).then(async (response: Response) => {
      switch (response.status) {
        case 200:
          let responseJSON = await response.json();
          window.localStorage.setItem(
            "access_token",
            responseJSON.data.access_token
          );
          window.localStorage.setItem(
            "refresh_token",
            responseJSON.data.refresh_token
          );
          window.open(virtualSpacePath, "_self");
          break;
        default:
          break;
      }
    });
  }

  return (
    <div
      className="bg-white bg-center bg-cover bg-no-repeat"
      style={
        {
          // backgroundImage: isMobile
          //   ? "url(./images/backgroundMobile.png)"
          //   : "url(./images/backgroundPC.jpg)",
        }
      }
    >
      <Header />
      <div className="w-screen h-screen">
        <div className="h-full flex flex-col justify-center items-center gap-4">
          <img
            className="w-full px-4 md:px-0 md:w-1/6 mt-20"
            src="./images/logo.png"
          />
          <h2 className="font-normal text-2xl text-black mt-12">
            {t("3D_COLLABORATIVE")}
          </h2>
          <h3 className="font-light text-xl text-black my-8 text-center">
            {t("ENJOY_EXPERIENCE")}
            <br />
            {t("ENJOY_EXPERIENCE_2")}
          </h3>
          <button
            onClick={() => window.open(virtualSpacePath)}
            className="bg-blueVirtual text-white uppercase text-center font-semibold py-4 leading-5 rounded-md w-[20rem] cursor-pointer hover:scale-105"
          >
            {t("ENTER_GUEST")}
          </button>
          <p className="text-black mt-2">
            {t("DONT_HAVE_ACCOUNT")}{" "}
            <span
              onClick={() => {
                navigate("/signup");
              }}
              className="font-medium cursor-pointer text-blueVirtual underline hover:no-underline"
            >
              {t("SIGNUP")}
            </span>
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Home;
